import type { Assessment } from '@/Assessment/types'
import type Results from './Results/index.js'

export default {
  id: 'symptomChecker',
  title: 'Symptom Check',
  breadcrumbGroup: 'symptoms',
  snippetName: 'symptoms',
  slots: {
    ai: lazyLoad(() => import('./Results/index.js')),
  },
  questions: [
    {
      name: 'symptoms',
      type: 'imageTextarea',
      text: 'What are your symptoms? The more descriptive you are, the better your results will be.',
      render: {
        text: false,
        shouldLockFocus: false,
      },
      attributes: {
        placeholder: true,
      },
    },

    {
      name: 'sex',
      type: 'radio',
      text: 'What is your biological sex?',
      trait: 'sex',
      constrain: {
        options: [
          { label: 'Female', value: 'female' },
          { label: 'Male', value: 'male' },
        ],
      },
    },

    {
      name: 'age',
      type: 'radio',
      text: 'How old are you?',
      trait: 'age',
      constrain: {
        options: [
          { label: 'Under 13', value: '0-12' },
          { label: '13 to 17', value: '13-17' },
          { label: '18 to 24', value: '18-24' },
          { label: '25 to 34', value: '25-34' },
          { label: '35 to 44', value: '35-44' },
          { label: '45 to 54', value: '45-54' },
          { label: '54 to 65', value: '55-64' },
          { label: '65 or older', value: '65+' },
        ],
      },
    },

    {
      name: 'hasHistory',
      type: 'radio',
      text: 'Do you have any pre-existing health conditions?',
      trait: 'hasHistory',
      constrain: {
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      },
      condition: {
        history: '!undefined',
      },
    },

    {
      name: 'history',
      type: 'textarea',
      text: 'Describe your pre-existing health conditions.',
      trait: 'history',
      attributes: {
        placeholder: 'e.g. Type 2 diabetes',
      },
      condition: {
        history: '!undefined',
        hasHistory: true,
      },
    },

    {
      name: 'ai',
      type: 'ai',
      ephemeral: true,
      attributes: {
        view: 'symptoms',
        phase: {
          name: 'questions',
          min: 2,
          max: 3,
        },
      },
      slots: {
        Component: 'ai',
      },
    },

    // {
    //   name: 'shouldEmail',
    //   type: 'radio',
    //   text: 'Would you like a copy of the results emailed to you?',
    //   constrain: {
    //     options: [
    //       { label: 'Yes', value: true },
    //       { label: 'No', value: false },
    //     ],
    //   },
    // },

    // {
    //   name: 'email',
    //   type: 'email',
    //   trait: 'email',
    //   text: 'What is your email address?',
    //   // By submitting your email you agree to the terms of use and privacy policy
    //   condition: {
    //     shouldEmail: true,
    //   },
    //   attributes: {
    //     placeholder: 'e.g. alice@domain.com',
    //   },
    // },

    // {
    //   name: 'ai',
    //   type: 'ai',
    //   attributes: {
    //     summarize: true,
    //     view: 'symptoms',
    //     phase: {
    //       name: 'conditions',
    //     },
    //   },
    //   slots: {
    //     Component: 'ai',
    //   },
    // },
  ],
} satisfies Assessment<{
  ai: typeof Results
}>
